<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="View Payment Request" :active="isActive" v-on:update:active="emitModalIsActive">
      <div :class="{'opacity-50 pointer-events-none': loading.initData}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nomor Payment Request</label>
            <vs-input class="w-full" :value="data.header.no_payment_request" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl Payment Request</label>
            <vs-input class="w-full" :value="data.header.tgl_payment_request" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Jumlah Payment Voucher</label>
            <vs-input class="w-full" :value="data.details.length + ' PV'" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Status Approval</label>
            <vs-input class="w-full" :value="data.header.status_approval" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Total Nilai</label>
            <v-money class="w-full" :value="data.header.total_nilai" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Total Bayar</label>
            <v-money class="w-full" :value="data.header.total_bayar" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Total Balance</label>
            <v-money class="w-full" :value="data.header.total_nilai - data.header.total_bayar" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" :value="data.header.keterangan || '-'" readonly/>
          </div>
        </div>

        <vs-divider class="mt-base">PV Yang Diajukan</vs-divider>

        <div class="h-64 overflow-y-auto">
          <vs-table :data="data.details" stripe>
            <template slot="thead">
              <vs-th class="whitespace-no-wrap">#</vs-th>
              <vs-th class="whitespace-no-wrap">Nomor PV</vs-th>
              <vs-th class="whitespace-no-wrap">Tipe</vs-th>
              <vs-th class="whitespace-no-wrap">Tanggal PV</vs-th>
              <vs-th class="whitespace-no-wrap">Nama Rekanan</vs-th>
              <vs-th class="whitespace-no-wrap">Total Nilai</vs-th>
              <vs-th class="whitespace-no-wrap">Total Bayar</vs-th>
              <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                <vs-td>{{ index+1 }}</vs-td>
                <vs-td>{{ item.no_pv }}</vs-td>
                <vs-td>{{ item.tipe }}</vs-td>
                <vs-td>{{ item.tgl_pv }}</vs-td>
                <vs-td>{{ item.nama_rekanan }}</vs-td>
                <vs-td class="text-right">{{ item.total_nilai | idr }}</vs-td>
                <vs-td class="text-right">{{ item.total_bayar | idr }}</vs-td>
                <vs-td>{{ item.keterangan || '-' }}</vs-td>
              </vs-tr>
              <!--footer-->
              <vs-tr v-if="data.length > 0">
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td>Total</vs-td>
                <vs-td class="text-right">{{ grandTotalNilai | idr }}</vs-td>
                <vs-td class="text-right">{{ grandTotalBayar | idr }}</vs-td>
                <vs-td></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ApRepository from '@/repositories/accounting/ap-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import VMoney from '../../../../../components/v-money/VMoney'

export default {
  name: 'ViewPaymentRequest',
  props: ['isActive', 'idPaymentRequest'],
  components: {
    VMoney
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      loading: {
        initData: false
      },
      modalFiles: {
        active: false
      },
      data: {
        header: {},
        details: []
      }
    }
  },
  computed: {
    grandTotalNilai () {
      return _.sumBy(this.data.details, item => item.total_nilai)
    },
    grandTotalBayar () {
      return _.sumBy(this.data.details, item => item.total_bayar)
    }
  },
  methods: {
    getInitData () {
      this.loading.initData = true

      ApRepository.showPaymentRequest(this.idPaymentRequest)
        .then(response => {
          this.data = { ...this.data, ...response.data.data }
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.initData = false
        })
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
